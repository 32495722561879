@import "../node_modules/@clr/icons/clr-icons.min.css";
@import "../node_modules/@clr/ui/clr-ui.min.css";

@import "../src/lib/splitter.css";

@import "../node_modules/quill/dist/quill.core.css";
@import "../node_modules/quill/dist/quill.snow.css";

@import "../src/assets/scss/grid.scss";

@import "../node_modules/jquery-timepicker/jquery.timepicker.css";

/* You can add global styles to this file, and also import other style files */
html,
body {
    height: 100%;
    width: 100%;
    background: #e6e6e6;
}

.ui-draggable-dragging {
    opacity: 0.7;
    z-index: 16;
}

.ui-droppable-hover.ui-droppable-active,
.ui-state-highlight {
    outline: 1px dashed #333;
    outline-offset: -1px;
    background: #e6e6e6;
}

.clr-tree-node-content-container {
    outline: none;
}

.ui-state-highlight {
    padding: 0;
    margin: 0;
    z-index: 1;
}

.btn {
    border-color: #ff0000;
    color: #a32100;
    cds-icon {
        color: #a32100;
    }

    &:hover {
        background-color: #ffe0e3;
        border-color: #a32100;
        color: #a32100;
        cds-icon {
            color: #a32100;
        }
    }
    &:active {
        box-shadow: 0 0 0 0;
        outline: none;
    }
    &.btn-primary {
        background-color: #ff0000;
        border-color: #ff0000;
        &:hover {
            background-color: #a32100;
            border-color: #a32100;
        }
        &:active {
            box-shadow: 0 0 0 0;
            outline: none;
        }
    }
}

.radio.btn input[type="radio"]:checked + label,
.radio.btn input[type="radio"]:checked + label > clr-icon,
.radio.btn input[type="radio"]:checked + label > cds-icon,
.radio.btn input[type="radio"]:checked + label,
.radio.btn input[type="radio"]:checked + label > clr-icon,
.radio.btn input[type="radio"]:checked + label > cds-icon {
    background-color: #ff0000;
    color: #fff;
}

.action-link {
    font-size: 0.58rem;
    color: #ff0000;
    cursor: pointer;
    margin: 0 10px;

    &.disabled {
        color: #333;
        text-decoration: none;
    }
}

.action-link:not(.disabled):hover {
    text-decoration: underline;
}

ul[role="tablist"] {
    li[role="presentation"] {
        button {
            border: 0 !important;
            &.nav-link.active,
            &:hover {
                box-shadow: 0 -0.15rem 0 #ff0000 inset;
            }
        }
    }
}

.clr-input:not([readonly]),
.clr-select:not([readonly]) {
    &:focus {
        background-size: 0 0;
        border-color: #ff0000;
    }
}
.clr-textarea {
    border-radius: 0;
    &:focus {
        box-shadow: 0 0 0 0;
        border-color: #ff0000;
    }
}

.clr-input-group {
    background: none;
    &.clr-focus {
        border-color: #ff0000;
    }
    input {
        border-width: 0px;
    }
}

.modal-body {
    line-height: 1rem;
    outline: none;
}

a:visited {
    color: inherit;
}

.no-margin {
    margin: 0;
}

.clr-combobox-options-text {
    background: red !important;
    display: none !important;
    visibility: hidden !important;
}

.tags {
    padding: 0.25rem 0;
    span.tag {
        display: inline-block;
        padding: 0 5px;
        background: red;
        color: white;
        border-radius: 3px;
        cursor: pointer;

        margin: 2px;
        font-size: 0.45rem;
        clr-icon {
            margin-top: -2px;
        }
        &.invisible {
            opacity: 0.3;
        }
    }
}

h1,
h2,
h3,
h4,
h5,
h6 {
    font-style: normal;
    font-variant: normal;
    font-weight: 700;
}

.input-castlug-container {
    display: block;
    width: gridSquareSize(4);
    height: gridSquareSize(1) + $margin;
    overflow: auto;
    padding: 0;
    border-radius: $borderRadius;
    margin: $margin;

    input {
        display: block;
        border: none;
        height: gridSquareSize(1);
        width: 100%;
        border-radius: $borderRadius;
        padding: 0 0.25rem;
        margin: 0;
        box-shadow: $shadow;
        font-family: "Arial Rounded MT Bold", "Helvetica Rounded", Arial, sans-serif !important;

        &::placeholder,
        &::-webkit-input-placeholder {
            color: #ff0000;
            opacity: 1;
        }

        &:focus {
            outline: none;
        }
    }
}

.ql-editor {
    max-height: 150px;
    overflow: auto;
    * {
        font-family: Calibri, Candara, Segoe, Segoe UI, Optima, Arial, sans-serif !important;
    }
    p {
        margin-top: 0 !important;
    }
}

@media print {
    body,
    html {
        background: white;
    }

    header.header {
        display: none !important;
    }

    .content-area,
    .padding-menu {
        padding: 0 !important;
        margin: 0 !important;
    }

    .version {
        display: none !important;
    }
}

.ui-timepicker {
    ul.ui-timepicker-viewport {
        list-style: none;
        li.ui-menu-item {
            font-size: 0.65rem;
        }
    }
}

.ui-resizable-handle.ui-icon-gripsmall-diagonal-se {
    position: absolute;
    bottom: 5px;
    right: 2px;
    width: 0;
    height: 0;
    transform: rotate(315deg);
    opacity: 0.8;
    border-left: 6px solid transparent;
    border-top: 6px solid rgb(177, 177, 177);
    border-right: 6px solid transparent;
    cursor: se-resize;
}
