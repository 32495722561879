
.e-splitter .e-split-bar.e-split-bar-horizontal .e-resize-handler::before {
    content: "|";
    font-size: 0.5px;
}
.e-splitter .e-split-bar.e-split-bar-vertical .e-resize-handler::before {
    content: "-";
    font-size: 0.5px;
}

.e-splitter.e-splitter-horizontal,
.e-splitter.e-splitter-vertical {
    border-width: 1px;
    display: -ms-flexbox;
    display: flex;
    overflow: hidden;
    position: relative;
    -ms-touch-action: none;
    touch-action: none;
    width: 100%;
}

.e-splitter.e-splitter-horizontal .e-pane,
.e-splitter.e-splitter-vertical .e-pane {
    -ms-flex: 1 1 auto;
    flex: 1 1 auto;
}

.e-splitter.e-splitter-horizontal .e-pane.e-scrollable,
.e-splitter.e-splitter-vertical .e-pane.e-scrollable {
    overflow: auto;
}

.e-splitter.e-splitter-horizontal .e-pane.e-static-pane,
.e-splitter.e-splitter-vertical .e-pane.e-static-pane {
    -ms-flex-positive: 0;
    flex-grow: 0;
    -ms-flex-negative: 0;
    flex-shrink: 0;
}

.e-splitter.e-splitter-horizontal .e-pane.e-pane-horizontal,
.e-splitter.e-splitter-vertical .e-pane.e-pane-horizontal {
    overflow: auto;
}

.e-splitter.e-splitter-horizontal .e-pane.e-pane-horizontal.e-pane-hidden,
.e-splitter.e-splitter-vertical .e-pane.e-pane-horizontal.e-pane-hidden {
    -ms-flex-preferred-size: 0 !important;
    flex-basis: 0 !important;
    overflow: hidden !important;
}

.e-splitter.e-splitter-horizontal {
    -ms-flex-direction: row;
    flex-direction: row;
}

.e-splitter.e-splitter-horizontal.e-pane.e-scrollable {
    overflow: hidden;
}

.e-splitter.e-splitter-horizontal.e-rtl {
    -ms-flex-direction: row-reverse;
    flex-direction: row-reverse;
}

.e-splitter.e-splitter-horizontal.e-rtl .e-split-bar.e-split-bar-horizontal {
    -ms-flex-direction: row-reverse;
    flex-direction: row-reverse;
}

.e-splitter.e-splitter-horizontal.e-rtl
    .e-split-bar.e-split-bar-horizontal
    .e-navigate-arrow:not(.e-arrow-right)::before,
.e-splitter.e-splitter-horizontal.e-rtl
    .e-split-bar.e-split-bar-horizontal
    .e-navigate-arrow:not(.e-arrow-right)::after {
    right: auto;
}

.e-splitter.e-splitter-vertical {
    -ms-flex-direction: column;
    flex-direction: column;
}

.e-splitter.e-splitter-vertical.e-pane.e-scrollable {
    overflow: hidden;
}

.e-splitter.e-splitter-vertical .e-pane-vertical {
    overflow: auto;
}

.e-splitter.e-splitter-vertical .e-pane-vertical.e-pane-hidden {
    -ms-flex-preferred-size: 0 !important;
    flex-basis: 0 !important;
    overflow: hidden !important;
}

.e-splitter.e-splitter-vertical.e-rtl .e-split-bar.e-split-bar-vertical .e-navigate-arrow:not(.e-arrow-down)::before,
.e-splitter.e-splitter-vertical.e-rtl .e-split-bar.e-split-bar-vertical .e-navigate-arrow:not(.e-arrow-down)::after {
    right: auto;
}

.e-splitter.e-splitter-vertical.e-rtl .e-split-bar.e-split-bar-vertical .e-navigate-arrow:not(.e-arrow-down) {
    border-radius: 50%;
    bottom: 18px;
    right: 22px;
}

.e-splitter.e-splitter-vertical.e-rtl .e-split-bar.e-split-bar-vertical .e-navigate-arrow.e-arrow-down {
    border-radius: 50%;
    left: 22px;
    right: auto;
    top: 18px;
}

.e-splitter.e-splitter-vertical.e-rtl .e-split-bar.e-split-bar-vertical .e-navigate-arrow.e-arrow-down::after {
    right: auto;
}

.e-splitter .e-split-bar.e-split-bar-active .e-navigate-arrow {
    visibility: hidden;
}

.e-splitter .e-split-bar.e-split-bar-hover.e-split-bar-active .e-navigate-arrow {
    visibility: hidden;
}

.e-splitter .e-split-bar.e-split-bar-horizontal {
    -ms-flex-align: center;
    align-items: center;
    display: -ms-flexbox;
    display: flex;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    -ms-flex-pack: center;
    justify-content: center;
    min-width: 1px;
    z-index: 15;
}

.e-splitter .e-split-bar.e-split-bar-horizontal .e-icon-hidden {
    visibility: hidden;
}

.e-splitter .e-split-bar.e-split-bar-horizontal.e-resizable-split-bar {
    cursor: col-resize;
}

.e-splitter .e-split-bar.e-split-bar-horizontal.e-resizable-split-bar::after {
    content: "";
    cursor: col-resize;
    display: block;
    height: 100%;
    position: absolute;
    width: 16px;
    z-index: 10;
}

.e-splitter .e-split-bar.e-split-bar-horizontal .e-resize-handler {
    -ms-flex-align: center;
    align-items: center;
    display: -ms-flexbox;
    display: flex;
    height: 18px;
    -ms-flex-pack: center;
    justify-content: center;
    position: relative;
    width: 1px;
}

/* .e-splitter .e-split-bar.e-split-bar-horizontal .e-resize-handler.e-hide-handler {
    visibility: hidden;
} */

.e-splitter .e-split-bar.e-split-bar-horizontal .e-navigate-arrow:not(.e-arrow-right)::before,
.e-splitter .e-split-bar.e-split-bar-horizontal .e-navigate-arrow:not(.e-arrow-right)::after {
    left: 8px;
    right: 3px;
}

.e-splitter .e-split-bar.e-split-bar-horizontal .e-navigate-arrow {
    border-image: none;
    border-width: 1px;
    cursor: pointer;
    display: -ms-flexbox;
    display: flex;
    height: 26px;
    padding: 1px 12px;
    position: relative;
}

.e-splitter .e-split-bar.e-split-bar-horizontal .e-navigate-arrow::before,
.e-splitter .e-split-bar.e-split-bar-horizontal .e-navigate-arrow::after {
    border-radius: 16px;
    content: "";
    height: 8px;
    position: absolute;
    transform: rotate(0deg);
    width: 2px;
}

.e-splitter .e-split-bar.e-split-bar-horizontal .e-navigate-arrow::before {
    top: 5px;
    transform-origin: 1px 7px 0;
}

.e-splitter .e-split-bar.e-split-bar-horizontal .e-navigate-arrow::after {
    top: 11px;
    transform-origin: 1px 1px 0;
}

.e-splitter .e-split-bar.e-split-bar-horizontal .e-navigate-arrow:not(.e-arrow-right) {
    border-radius: 50%;
    right: 5px;
}

.e-splitter .e-split-bar.e-split-bar-horizontal .e-navigate-arrow.e-arrow-right {
    border-radius: 50%;
    left: 5px;
}

.e-splitter .e-split-bar.e-split-bar-horizontal .e-navigate-arrow.e-arrow-right::before,
.e-splitter .e-split-bar.e-split-bar-horizontal .e-navigate-arrow.e-arrow-right::after {
    right: 8px;
}

.e-splitter .e-split-bar.e-split-bar-horizontal.e-last-bar:not(.e-resizable-split-bar) {
    margin: 0 3px;
}

.e-splitter .e-split-bar.e-split-bar-horizontal:not(.e-resizable-split-bar) {
    margin: 0 0 0 3px;
}

.e-splitter .e-split-bar.e-split-bar-vertical {
    -ms-flex-align: center;
    align-items: center;
    border: none;
    display: -ms-flexbox;
    display: flex;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    -ms-flex-pack: center;
    justify-content: center;
    min-height: 1px;
}

.e-splitter .e-split-bar.e-split-bar-vertical .e-icon-hidden {
    visibility: hidden;
}

.e-splitter .e-split-bar.e-split-bar-vertical.e-resizable-split-bar {
    cursor: row-resize;
}

.e-splitter .e-split-bar.e-split-bar-vertical.e-resizable-split-bar::after {
    content: "";
    cursor: row-resize;
    display: block;
    height: 16px;
    position: absolute;
    width: 100%;
    z-index: 12;
}

.e-splitter .e-split-bar.e-split-bar-vertical .e-resize-handler {
    -ms-flex-align: center;
    align-items: center;
    display: -ms-flexbox;
    display: flex;
    height: 1px;
    -ms-flex-pack: center;
    justify-content: center;
    width: 18px;
}

.e-splitter .e-split-bar.e-split-bar-vertical .e-resize-handler.e-hide-handler {
    visibility: hidden;
}

.e-splitter .e-split-bar.e-split-bar-vertical .e-navigate-arrow:not(.e-arrow-down)::before,
.e-splitter .e-split-bar.e-split-bar-vertical .e-navigate-arrow:not(.e-arrow-down)::after {
    left: 10px;
}

.e-splitter .e-split-bar.e-split-bar-vertical .e-navigate-arrow {
    border-image: none;
    border-width: 0;
    cursor: pointer;
    display: -ms-flexbox;
    display: flex;
    height: 26px;
    padding: 10px 3px 10px 18px;
    position: relative;
    width: 26px;
}

.e-splitter .e-split-bar.e-split-bar-vertical .e-navigate-arrow::before,
.e-splitter .e-split-bar.e-split-bar-vertical .e-navigate-arrow::after {
    border-radius: 16px;
    content: "";
    height: 8px;
    position: absolute;
    transform: rotate(90deg);
    width: 2px;
}

.e-splitter .e-split-bar.e-split-bar-vertical .e-navigate-arrow:not(.e-arrow-down)::before {
    top: 3px;
    transform-origin: 2px 7px 0;
}

.e-splitter .e-split-bar.e-split-bar-vertical .e-navigate-arrow:not(.e-arrow-down)::after {
    left: 11px;
    top: 8px;
    transform-origin: 1px 1px 0;
}

.e-splitter .e-split-bar.e-split-bar-vertical .e-navigate-arrow.e-arrow-down::before {
    left: 12px;
    top: 8px;
    transform-origin: 1px 6px 0;
}

.e-splitter .e-split-bar.e-split-bar-vertical .e-navigate-arrow.e-arrow-down::after {
    left: 10px;
    top: 12px;
    transform-origin: 1px 2px 0;
}

.e-splitter .e-split-bar.e-split-bar-vertical .e-navigate-arrow:not(.e-arrow-down) {
    border-radius: 50%;
    bottom: 18px;
    left: 22px;
}

.e-splitter .e-split-bar.e-split-bar-vertical .e-navigate-arrow.e-arrow-down {
    border-radius: 50%;
    right: 22px;
    top: 18px;
}

.e-splitter .e-split-bar.e-split-bar-vertical .e-navigate-arrow.e-arrow-down::before,
.e-splitter .e-split-bar.e-split-bar-vertical .e-navigate-arrow.e-arrow-down::after {
    right: 10px;
}

.e-splitter .e-split-bar.e-split-bar-vertical.e-last-bar:not(.e-resizable-split-bar) {
    margin: 3px 0;
}

.e-splitter .e-split-bar.e-split-bar-vertical:not(.e-resizable-split-bar) {
    margin: 3px 0 0;
}

.e-bigger.e-splitter .e-split-bar.e-split-bar-horizontal .e-resize-handler {
    height: 20px;
}

.e-bigger.e-splitter .e-split-bar.e-split-bar-vertical .e-resize-handler {
    width: 20px;
}

.e-bigger.e-splitter .e-split-bar.e-split-bar-vertical .e-navigate-arrow:not(.e-arrow-down)::before,
.e-bigger.e-splitter .e-split-bar.e-split-bar-vertical .e-navigate-arrow:not(.e-arrow-down)::after {
    margin-top: -2px;
}

.e-bigger.e-splitter .e-split-bar.e-split-bar-vertical .e-navigate-arrow.e-arrow-down::after {
    margin-top: 2px;
}

.e-rtl .e-splitter.e-splitter-horizontal {
    -ms-flex-direction: row-reverse;
    flex-direction: row-reverse;
}

.e-bigger .e-splitter .e-split-bar.e-split-bar-horizontal .e-resize-handler {
    height: 20px;
}

.e-bigger .e-splitter .e-split-bar.e-split-bar-vertical .e-resize-handler {
    width: 20px;
}

.e-content-placeholder.e-splitter.e-placeholder-splitter {
    background-size: 100px 110px;
    min-height: 110px;
}

.e-bigger .e-content-placeholder.e-splitter.e-placeholder-splitter,
.e-bigger.e-content-placeholder.e-splitter.e-placeholder-splitter {
    background-size: 100px 110px;
    min-height: 110px;
}

.e-splitter.e-splitter-horizontal .e-pane,
.e-splitter.e-splitter-vertical .e-pane {
    color: #333;
}

.e-splitter.e-splitter-horizontal .e-pane.e-splitter.e-splitter-horizontal,
.e-splitter.e-splitter-horizontal .e-pane.e-splitter.e-splitter-vertical,
.e-splitter.e-splitter-vertical .e-pane.e-splitter.e-splitter-horizontal,
.e-splitter.e-splitter-vertical .e-pane.e-splitter.e-splitter-vertical {
    border: transparent;
}

.e-splitter .e-split-bar.e-split-bar-horizontal {
    background: #dadada;
    border: none;
}

.e-splitter .e-split-bar.e-split-bar-horizontal .e-resize-handler {
    background: #fff;
    color: #333;
}

.e-splitter .e-split-bar.e-split-bar-horizontal .e-navigate-arrow {
    background-color: transparent;
    border-color: transparent;
    color: transparent;
    transition: background-color 500ms ease-out;
}

.e-splitter .e-split-bar.e-split-bar-horizontal .e-navigate-arrow::before,
.e-splitter .e-split-bar.e-split-bar-horizontal .e-navigate-arrow::after {
    background-color: #ea3622;
    opacity: 0;
    transition-duration: 0.3s;
    transition-timing-function: cubic-bezier(0.25, 0.1, 0.25, 1);
}

.e-splitter .e-split-bar.e-split-bar-horizontal.e-split-bar-hover {
    background: #ea3622;
}

.e-splitter .e-split-bar.e-split-bar-horizontal.e-split-bar-hover .e-resize-handler {
    background: #fff;
    color: #ea3622;
}

.e-splitter .e-split-bar.e-split-bar-horizontal.e-split-bar-hover .e-navigate-arrow.e-arrow-left,
.e-splitter .e-split-bar.e-split-bar-horizontal.e-split-bar-hover .e-navigate-arrow.e-arrow-right {
    background-color: #fff;
    border-color: rgba(0, 120, 214, 0.5);
    border-width: 1px;
    opacity: 0.9;
}

.e-splitter .e-split-bar.e-split-bar-horizontal.e-split-bar-hover.e-split-bar-hover .e-navigate-arrow::before,
.e-splitter .e-split-bar.e-split-bar-horizontal.e-split-bar-hover.e-split-bar-active .e-navigate-arrow::before {
    background-color: #ea3622;
    opacity: 1;
    transform: rotate(40deg);
}

.e-splitter .e-split-bar.e-split-bar-horizontal.e-split-bar-hover.e-split-bar-hover .e-navigate-arrow::after,
.e-splitter .e-split-bar.e-split-bar-horizontal.e-split-bar-hover.e-split-bar-active .e-navigate-arrow::after {
    background-color: #ea3622;
    opacity: 1;
    transform: rotate(-40deg);
}

.e-splitter .e-split-bar.e-split-bar-horizontal.e-split-bar-hover .e-navigate-arrow.e-arrow-right::before,
.e-splitter .e-split-bar.e-split-bar-horizontal.e-split-bar-active .e-navigate-arrow.e-arrow-right::before {
    background-color: #ea3622;
    opacity: 1;
    transform: rotate(-40deg);
}

.e-splitter .e-split-bar.e-split-bar-horizontal.e-split-bar-hover .e-navigate-arrow.e-arrow-right::after,
.e-splitter .e-split-bar.e-split-bar-horizontal.e-split-bar-active .e-navigate-arrow.e-arrow-right::after {
    background-color: #ea3622;
    opacity: 1;
    transform: rotate(40deg);
}

.e-splitter .e-split-bar.e-split-bar-horizontal.e-split-bar-active {
    background: #ea3622;
}

.e-splitter .e-split-bar.e-split-bar-horizontal.e-split-bar-active .e-resize-handler {
    background: #fff;
    color: #ea3622;
}

.e-splitter .e-split-bar.e-split-bar-vertical {
    background: #dadada;
}

.e-splitter .e-split-bar.e-split-bar-vertical .e-resize-handler {
    background: #fff;
    color: #333;
}

.e-splitter .e-split-bar.e-split-bar-vertical.e-split-bar-hover {
    background: #ea3622;
}

.e-splitter .e-split-bar.e-split-bar-vertical.e-split-bar-hover .e-navigate-arrow.e-arrow-up,
.e-splitter .e-split-bar.e-split-bar-vertical.e-split-bar-hover .e-navigate-arrow.e-arrow-down {
    background-color: #fff;
    border-color: rgba(0, 120, 214, 0.5);
    border-width: 1px;
    opacity: 0.9;
}

.e-splitter .e-split-bar.e-split-bar-vertical.e-split-bar-hover .e-resize-handler {
    background: #fff;
    color: #ea3622;
}

.e-splitter .e-split-bar.e-split-bar-vertical.e-split-bar-hover .e-navigate-arrow.e-arrow-up::before,
.e-splitter .e-split-bar.e-split-bar-vertical.e-split-bar-active .e-navigate-arrow.e-arrow-up::before {
    background-color: #ea3622;
    opacity: 1;
    transform: rotate(125deg);
}

.e-splitter .e-split-bar.e-split-bar-vertical.e-split-bar-hover .e-navigate-arrow.e-arrow-up::after,
.e-splitter .e-split-bar.e-split-bar-vertical.e-split-bar-active .e-navigate-arrow.e-arrow-up::after {
    background-color: #ea3622;
    opacity: 1;
    transform: rotate(50deg);
}

.e-splitter .e-split-bar.e-split-bar-vertical.e-split-bar-hover .e-navigate-arrow.e-arrow-down::before,
.e-splitter .e-split-bar.e-split-bar-vertical.e-split-bar-active .e-navigate-arrow.e-arrow-down::before {
    background-color: #ea3622;
    opacity: 1;
    transform: rotate(55deg);
}

.e-splitter .e-split-bar.e-split-bar-vertical.e-split-bar-hover .e-navigate-arrow.e-arrow-down::after,
.e-splitter .e-split-bar.e-split-bar-vertical.e-split-bar-active .e-navigate-arrow.e-arrow-down::after {
    background-color: #ea3622;
    opacity: 1;
    transform: rotate(125deg);
}

.e-splitter .e-split-bar.e-split-bar-vertical .e-navigate-arrow {
    background-color: transparent;
    border-color: transparent;
    color: transparent;
    transition: background-color 300ms ease-out;
}

.e-splitter .e-split-bar.e-split-bar-vertical .e-navigate-arrow::before,
.e-splitter .e-split-bar.e-split-bar-vertical .e-navigate-arrow::after {
    background-color: #ea3622;
    opacity: 0;
    transition-duration: 0.3s;
    transition-timing-function: cubic-bezier(0.25, 0.1, 0.25, 1);
}

.e-splitter .e-split-bar.e-split-bar-vertical.e-split-bar-active {
    background: #ea3622;
}

.e-splitter .e-split-bar.e-split-bar-vertical.e-split-bar-active .e-resize-handler {
    background: #fff;
    color: #ea3622;
}

.e-splitter.e-disabled {
    opacity: 00.4;
    pointer-events: none;
}
.e-splitter .e-split-bar.e-split-bar.e-split-bar-horizontal {
    width: 5px !important;
}
.e-splitter .e-split-bar.e-split-bar.e-split-bar-vertical {
    height: 5px !important;
}