
$margin: 0.25rem;
$squareSide: 2rem;
$borderRadius:0.125rem;
$shadow:2px 3px 6px 0px rgba(172, 172, 172, 1);


@function gridSquareSize($gridWidth:1) {
    $addMarginWidth :( $gridWidth - 1 ) * ( $margin * 2 );
    @return ( $gridWidth * $squareSide ) + $addMarginWidth;
}